
import Vue from 'vue';
import BillLayout from '@/layouts/BillLayout.vue';
import formTitle from '@/assets/data/formTitle/index.json';
import FormLayout from '@/layouts/FormLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';
import InfoPopover from '@/components/common/InfoPopover/InfoPopover.vue';

export default Vue.extend({
  name: 'CloudStep01Template',
  components: { BillLayout, TableLayout, ColumnLayout, FormLayout, InfoModal, InfoPopover },
  model: { prop: 'formState', event: 'change' },
  props: {
    formState: {
      type: Object,
      default() {
        return {
          terms: {
            agree1: false,
            agree2: false,
          },
        };
      },
    },
  },
  data() {
    return {
      formTitle,
      agreeAll: false,
      cloudTermsSrc: `${process.env.VUE_APP_POLICY_URL}/cloud/?page=application`,
      myInfoCheckSrc: `${process.env.VUE_APP_GABIA_URL}/member/member_account_modify.php`,
    };
  },
  computed: {
    agreeBoth(): boolean {
      return !!(this.formState.terms.agree1 && this.formState.terms.agree2);
    },
    userInfo(): any {
      return this.$store.state.userInfo;
    },
    isMobileAuth(): boolean {
      return this.$store.state.isMobileAuth;
    },
    showUserType(): string {
      return this.userInfo.user_type === 'organization' ? '단체' : '개인';
    },
  },
  watch: {
    agreeBoth(newValue) {
      this.agreeAll = newValue;
    },
  },
  mounted() {
    const iframe = document.createElement('iframe');
    iframe.src = this.cloudTermsSrc;
    iframe.width = '800';
    iframe.height = '800';
    iframe.frameBorder = '0';

    const modalContent = document.querySelector('#cloudTermsModal');
    modalContent?.appendChild(iframe);
  },
  methods: {
    checkPhoneAuth() {
      this.$emit('click-nice-auth');
    },
    onChangeAgreeAll(value) {
      if (value) {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: true,
            agree2: true,
          },
        });
      } else {
        this.$emit('change', {
          ...this.formState,
          terms: {
            agree1: false,
            agree2: false,
          },
        });
      }
    },
    onChangeTerms(e) {
      const { name, checked } = e;

      this.$emit('change', {
        ...this.formState,
        terms: {
          ...this.formState.terms,
          [`agree${name}`]: checked,
        },
      });
    },
    openCloudTermModal() {
      this.$GabiaModal.show('terms-cloud');
    },
    openIllegalTermModal() {
      this.$GabiaModal.show('terms-illegal');
    },
  },
});
