
import Vue from 'vue';
import { CustomVueRefs } from '@/types/common';
import popupWindow from '@/utils/popupWindow';
import { getCertifyGabiaAuth } from '@/api/cloud/index';
import CalculatorScreen from '@/components/common/CalculatorScreen/CalculatorScreen.vue';
import CloudStep01Template from '@/templates/cloud/CloudStep01Template.vue';
import { HttpStatusCode } from '@/types/common/index';

export default (Vue as CustomVueRefs<{ form_chk: InstanceType<typeof HTMLFormElement> }>).extend({
  name: 'CloudStep01Brain',
  components: { CalculatorScreen, CloudStep01Template },
  props: {},
  data() {
    return {
      formState: {
        terms: {
          agree1: false,
          agree2: false,
        },
      },
    };
  },
  computed: {},
  mounted() {
    window.vm = this;
    this.$GlobalLoading.hide();
  },
  methods: {
    makeOwnerPhoneAuth(cloudAuthSess: string) {
      alert(`본인인증에 성공하였습니다.`);
      this.$store.dispatch('setAuthSess', cloudAuthSess);
      this.$store.dispatch('setMobileAuth', true);
    },
    async getNiceAuth() {
      this.$GlobalLoading.show();
      try {
        const { data, status } = await getCertifyGabiaAuth();

        if (HttpStatusCode.CREATED === status) {
          const encInput = document.getElementById('encData') as HTMLInputElement;
          encInput.value = data.DATA;
          popupWindow('', 'popupChk', 500, 730);
          this.$refs.form_chk.submit();
        }
      } catch (error) {
        console.warn(error);
      } finally {
        this.$GlobalLoading.hide();
      }
    },
    goNextStep() {
      if (!this.formState.terms.agree1 || !this.formState.terms.agree2) {
        alert('모든 약관에 동의해주세요.');
        return;
      }

      if (!this.$store.state.isMobileAuth) {
        alert('휴대폰 본인인증을 진행해 주세요 ');
        return;
      }
      this.$router.push({
        name: 'apply-02',
        params: { service_type: 'cloud' },
        query: { auth_sess: this.$store.state.cloudAuthSess },
      });
    },
  },
});
